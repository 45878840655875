import React, {useEffect, useState} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QDate from "../../../../common/components/QDate/QDate";
import QSelect from "../../../../common/components/QSelect";
import {Col, Container, Row} from "reactstrap";
import {ProductInterface} from "../../../../interfaces/private/ProductInterface";
import ProductApi from "../../../../common/api/functions/Product";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {config} from "../../../../config";
import QButton from "../../../../common/components/QButton";
import QModal from "../../../../common/components/QModal";

interface Props {
    open: number, // open > 0 show,
    setOpen: Function,
}

const BodyProductDetailComponent: React.FC<Props> = ({setOpen, open}) => {
    const id = open;
    const [product, setProduct] = useState <ProductInterface|any>({})
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(id > 0){
            ProductApi.getDetail(id).then( async (response: ResponseInterface|any) => {
                if(response?.status === config.STATUS_CODE.OK){
                    await setProduct(response?.data?.data || {})
                    await setLoading(false);
                }
            })

        }

        return () => {
            setLoading(true);
        }
    }, [id])

    return (
        <React.Fragment>
            <QModal
                loading={loading}
                open={open > 0}
                setOpen={() => { setOpen(0) }}
                headerContent={'Product detail'}
                className={'modal-md-zp'}
                bodyContent={(
                    <React.Fragment>
                        <Container>
                            <Row>
                                <Col>
                                    <QTextBox label={'SKU'} disabled={true} value={product.productNumber} onChange={() => {}} />
                                </Col>
                                <Col>
                                    <QTextBox label={'Name'} disabled={true} value={product.name} onChange={() => {}} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QTextBox label={'Sale price'} disabled={true} value={product.salesPrice} onChange={() => {}} />
                                </Col>
                                <Col>
                                    <QTextBox label={'Cost price'} disabled={true} value={product.costPrice} onChange={() => {}} />
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <QTextBox label={'Group'} disabled={true} value={product.productGroup_name} onChange={() => {}} />
                                </Col>
                                <Col>
                                    <QTextBox label={'Customer'} disabled={true} value={product.customerNumber} onChange={() => {}} />
                                </Col>

                            </Row>

                            <Row>
                                <Col>
                                    <QTextBox label={'Weight'} disabled={true} value={product.inventory_grossWeight || 0} onChange={() => {}} />
                                </Col>
                                <Col />
                            </Row>

                            <Row>
                                <Col>
                                    <div className={'d-flex justify-content-end gap-2'}>
                                        <QButton
                                            type={'default'}
                                            onClick={() => {  setOpen(0) }}
                                            content={'Cancel'}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                )}
            />
        </React.Fragment>
    )
}

export default BodyProductDetailComponent