import {RouteInterface} from "../interfaces/RouteInterface";
import {Register} from "../features/public/Register/Register";
import ResetPassword from "../features/public/ResetPassword/ResetPassword";
import ForgotPassword from "../features/public/ResetPassword/ForgotPassword";
import { EconomicMarket } from "../features/public/EconomicMarket/EconomicMarket";
import Setup from "../features/public/Setup";
import Document from "../features/private/Document";
export const publicRoute: Array<RouteInterface> = [

    {path: '/economic-market', Component: EconomicMarket},
    {path: '/setup', Component: Setup},
    {path: '/document', Component: Document},

]
