import {config} from "../../config";
// @ts-ignore
import CryptoJS from "crypto-js";
const generateUrlEconomic = (redirectUri: string = "") => {
    return `https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=${config.INTEGRATION.ECONOMIC.PUBLIC_TOKEN}&redirectUrl=${redirectUri ? redirectUri : config.INTEGRATION.ECONOMIC.REDIRECT_URL}`;
}

const encrypt = (str: string) => {
    try {
        return  CryptoJS.AES.encrypt(str, config.HASH).toString();
    }catch ({}) { }
    return '';
}

const decrypt = (str: string|any) => {
    try {
        return CryptoJS.AES.decrypt(str, config.HASH).toString(CryptoJS.enc.Utf8);
    }catch ({}) {  }
    return '';
}

export const __string = {
    generateUrlEconomic,
    encrypt,
    decrypt
}
