import React, { useEffect, useState } from "react";
import OrderApi from "../../../../common/api/functions/Order";
import QModal from "../../../../common/components/QModal";
import { LogOrderInterface } from "../../../../interfaces/private/OrderInterface";
import PopupDetailLog from "./PopupDetailLog";

interface Props {
    logs: Array<LogOrderInterface>,
    orderId: number | any
}

const PopupLog: React.FC<Props> = ({ logs, orderId }) => {
    const [openDetailLog, setOpenDetailLog]:any = useState('');
    return (
        <React.Fragment>
            <div className="timeline-2 timeline-ct-2 mt-3" style={{ maxHeight: '70vh', overflowY: 'auto' }} >
                <div className="timeline-continue">
                    {logs?.map((log: LogOrderInterface, index: number) => {
                        return (
                            <div className="d-flex timeline-right mb-5" key={index}>
                                <div className="w-140">
                                    {log.time_ago}
                                </div>
                                <div className="w-auto position-relative">
                                    <p className="timeline-date">
                                        {log.message}
                                    </p>
                                </div>
                                <a onClick={() => { setOpenDetailLog(log._id) }}><i className="las la-pen fs-16 cl-br-ams ml-2"></i></a>
                            </div>
                        )
                    })}
                </div>
            </div>
            <PopupDetailLog
                open={openDetailLog}
                setOpen={setOpenDetailLog}
                orderId={orderId} />
        </React.Fragment>
    )
}

export default PopupLog;
