export const config = {
    HASH: "ebe587e6c53fa4ed7db0a70fc825d406cc522a1b",
    // API_URL: "http://localhost:8000/api",
    // API_URL: "http://localhost/2app-shipping-e-conomic/public/api",
    API_URL: "https://shipping.2app.dk/back/public/api",
    // API_URL: "http://t430.dragonflyteam.com/back/public/api",
    APP_NAME: "",
    STATUS_CODE: {
        OK: 200,
        BAD_REQUEST: 400,
        FORBIDDEN: 403,
        SERVER_ERROR: 500,
    },
    LOCAL_STORE: {
        ACCOUNT: 'account',
        ACCOUNT_SWITCH: 'aw',
        ACCESS_TOKEN: 'access_token',
        ROLE: 'r',
    },
    TOAST_TYPE: {
        INFO: 'info',
        SUCCESS: 'success',
        ERROR: 'error',
        WARNING: 'warning',
    },
    INTEGRATION: {
        ECONOMIC: {
            REDIRECT_URL: 'https://shipping.2app.dk/economic',
            PUBLIC_TOKEN: "xEWLdWWSPz44evs2ENyBJnuO24k8XAXZ1LHnBd78Ww41",
            REDIRECT_URL_SETUP: "https://shipping.2app.dk/setup",
        }

    }
}
