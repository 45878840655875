import React, {useEffect, useState} from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import AuthApi from "../../../common/api/functions/Auth";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {config} from "../../../config";
import {useHistory, useLocation} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";
import {useDispatch} from "react-redux";
import {setToast, setUserRole} from "../../../app/rootReducer";
import {QHelpers} from "../../../common/helpers";
import QModal from "../../../common/components/QModal";
import {GeneralConstant} from "../../../common/constant/GeneralConstant";
import UserApi from "../../../common/api/functions/User";

export function EconomicMarket() {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);
    // if(objectParams?.token){
    //     let token = objectParams?.token;
    // }
    useEffect(() => {
        
        if (loading) {
            loadRedirect().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadRedirect = async () => {
        try {
            let model = {
                token : objectParams?.token
            }
            await UserApi.getInfoCompany(model).then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    response = response.data.data;
                    return history.push(RouteDefine.register+'?token='+model.token,response);
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }
    return (
        <div>
            
        </div>
    )
}
