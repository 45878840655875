import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import { BusinessInterface } from "../../../interfaces/private/BusinessInterface";
import BusinessApi from "../../../common/api/functions/Business";
import { UseSecondLayout } from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import BodyIntegrateComponent from "./components/BodyIntegrateComponent";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import { AccountInterface } from "../../../interfaces/private/AccountInterface";
import BodyAccountComponent from "./components/ChangePassword";

export function Business() {
    const [business, setBusiness] = useState<BusinessInterface>({
        address: undefined,
        city: undefined,
        company_name: undefined,
        country: undefined,
        created_at: undefined,
        cvr_no: undefined,
        email: undefined,
        id: undefined,
        name: undefined,
        phone: undefined,
        updated_at: undefined,
        website: undefined,
        zip: undefined,
        password: undefined
    });

    const [account, setAccount] = useState<AccountInterface>({
        current_password: undefined,
        new_password: undefined,
        new_password_confirm: undefined
    });
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [createNewClick, setCreateNewClick] = useState(false);
    const [updateNewClick, setUpdateNewClick] = useState(false);
    UseSecondLayout();
    useEffect(() => {
        if (loading) {
            loadBusiness().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadBusiness = async () => {
        try {
            await BusinessApi.detail().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setBusiness(
                        response?.data?.data || []
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={setCreateNewClick}
                headerContent={'GLS Info'}
                bodyContent={(
                    <BodyIntegrateComponent
                        model={business}
                        setOpen={setCreateNewClick}
                        setBusiness={setBusiness}
                        setLoadingFunc={setLoading}
                    />
                )}
            />
            <QModal
                open={updateNewClick}
                setOpen={setUpdateNewClick}
                headerContent={'Account Info'}
                bodyContent={(
                    <BodyAccountComponent
                        model={account}
                        setOpen={setUpdateNewClick}
                        setAccount={setAccount}
                        setLoadingFunc={setLoading}
                    />
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading} />
                <div className="row ">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">Business detail</div>
                            </div>
                        </div>
                        <div className="col-lg-12 ">
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">{business.name}</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                            data-bs-target="#showModalBusinessDetail"><i
                                                className="las la-pen fs-24 cl-br-ams ml-2"
                                                onClick={() => {
                                                    setCreateNewClick(true);
                                                }}
                                            /></a>
                                    </div>
                                </div>
                                <p className="mb-1">{business.address}</p>
                                <p>{business.city} {business.country}</p>
                                <div className="row">
                                    <div className="col-md-4 mb-2"><span>CRV:</span> {business.cvr_no}</div>
                                    <div className="col-md-4 mb-2"><p /></div>
                                    <div className="col-md-4 mb-2"><p /></div>
                                    <div className="col-md-4 mb-2"><span>Email:</span>{business.email}</div>
                                    <div className="col-md-4 mb-2"><span>website:</span>{business.website}</div>
                                    <div className="col-md-4 mb-2"><span>Phone:</span> {business.phone}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

// @ts-ignore
export default React.memo(Business);
