import {TableHeaderInterface} from "../../../../interfaces/TableHeaderInterface";

const headers : Array<TableHeaderInterface> = [
    {
        label: "#",
    },
    {
        label: "Email",
        sort: 'email',
        className: "w-10p"
    },
    {
        label: "Name",
        className: "w-15p"
    },
    {
        label: "Company Name",
        sort: 'date',
        className: "w-10p"
    },
    {
        label: "Address",
        sort: 'delivery_deliveryDate',
        className: "w-10p"
    },
    {
        label: "CVR No",
        className: "w-15p"
    },
    {
        label: "Phone",
        className: "w-15p"
    },
    {
        label: "Switch",
    },
]

export const UserConfig = {
    headers,
}
