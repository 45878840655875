import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import { UseExtLayout, UseSecond01Layout, UseSecondLayout } from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import SetupApi from "../../../common/api/functions/Setup";
import { SetupInterface } from "../../../interfaces/private/SetupInterface";
import { config } from "../../../config";
import { QHelpers } from "../../../common/helpers";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import login from "../../../assets/images/login.png";
import createAccount from "../../../assets/images/create-account.png";
import economicToken from "../../../assets/images/economic-token.png";
import addTokenApp from "../../../assets/images/add-token-app.png";
import showEconomicToken from "../../../assets/images/show-economic-token.png";
import webhook from "../../../assets/images/webhook.png";
import webhookEconomic from "../../../assets/images/webhook-economic.png";
import webhookEconomicForm from "../../../assets/images/webhook-economic-form.png";
import shippingInformation from "../../../assets/images/shipping-information.png";
import successFirstLogin from "../../../assets/images/success-first-login.png";
import forgotPassword from "../../../assets/images/forgot-password.png";
import verifyEmail from "../../../assets/images/verify-email.png";
import verifyForgotPassword from "../../../assets/images/verify-forgot-password.png";
import forgotPasswordClient from "../../../assets/images/forgot-password-client.png";
import resetPassword from "../../../assets/images/reset-password.png";
import orders from "../../../assets/images/orders.png";
import openTab from "../../../assets/images/open-tab.png";
import onHoldTab from "../../../assets/images/on-hold-tab.png";
import unvailableTap from "../../../assets/images/unvailable-tap.png";
import onHoldSubmit from "../../../assets/images/on-hold-submit.png";
import onHoldTab2 from "../../../assets/images/on-hold-tab-2.png";
import changeOpenStatus from "../../../assets/images/change-open-status.png";
import shipButton from "../../../assets/images/ship-button.png";
import shipMethod from "../../../assets/images/ship-method.png";
import orderAfterShipped from "../../../assets/images/order-after-shipped.png";
import economicUpdate from "../../../assets/images/economic-update.png";
import logs from "../../../assets/images/logs.png";
import BusinessInfo from "../../../assets/images/business-info.png";
import changePassword from "../../../assets/images/change-password.png";
import changePassword2 from "../../../assets/images/change-password-2.png";
import economic from "../../../assets/images/economic.png";
import addApp from "../../../assets/images/add-app.png";
import generateToken from "../../../assets/images/generate-token.png";
import economicCopyAndPaste from "../../../assets/images/economic-copy-and-patse.png";
import webhookEconomicSetting from "../../../assets/images/webhook-economic-setting.png";
import webhookShortcuts from "../../../assets/images/webhook-shortcuts.png";
import shipment from "../../../assets/images/shipment.png";
import granteApp from "../../../assets/images/grante-app.png";
import { generateKey } from "crypto";
export function Document() {
    UseSecond01Layout();
    const history = useHistory();
    return (
        <div className={'row'}>
            <div className={"col-md-3 side-bar-document"}>
                <ul>
                    <li>
                        <a href="#create-new-account">1. Create New Account</a>
                    </li>
                    <li> <a href="#first-login">2. The First Login</a>
                    </li>
                    <li> <a href="#forgot-password">3. Forgot Your Password</a>
                    </li>
                    <li><a href="#shipping-management">4. Shipping Management</a>
                    </li>
                    <li><a href="#setting">5. Setting</a></li>
                    <li><a href="#shipped-information">6. Shipped Information</a></li>
                </ul>
            </div>
            <div className={"col-md-8"}>
                <section id="create-new-account">
                    <h1 className="text-danger text-center">The 2app shipping to Economic</h1>
                    <h4 className="text-center">The 2app shipping system supports the management of ship information sent from Economic</h4>
                    <h5>1. Create new account</h5>
                    <div className="text-center">
                        <a href="#"><img src={login} height={400} /></a>
                    </div>
                    <p className="text-center"><b>Click on link "Sign up" </b></p>
                    <p className="text-center"><b>Input information and then click button "Create Account", system will send mail verify new account. </b></p>
                    <div className="text-center">
                        <a href="#"><img src={createAccount} height={400} /></a>
                    </div>
                    <p className="text-center">System will send mail verify new account and you need to click on link from mail for login new account</p>
                    <div className="text-center">
                        <a href="#"><img src={verifyEmail} height={200} /></a>
                    </div>
                </section>
                <section id="first-login">
                    <h5>2. The first login</h5>
                    <p >After entering your email address and password, you set up so that the system can connect to the economic and shipping system with the following 3 steps:</p>
                    <ul>
                        <li>
                            <h5>Step 1:Economic</h5>
                            <p>
                                The required condition to perform this step is to login to your economic account.
                                Click on link Generate new token, the system automatically connects to the economic system.
                            </p>
                            <div className="text-center">
                                <a href="#"><img src={economicToken} height={400} /></a>
                            </div>
                            <p>Click on Add app to create a Token to connect the shipping app and economic.</p>
                            <div className="text-center">
                                <a href="#"><img src={addTokenApp} height={400} /></a>
                            </div>
                            <p>Token will be automatically copied to Step 1 and then Next to Step 2</p>
                            <div className="text-center">
                                <a href="#"><img src={showEconomicToken} height={400} /></a>
                            </div>
                        </li>
                        <li>
                            <h5>Step 2:Copy information Webhook by click icon  <i className="las la-copy"></i></h5>
                            <p>
                                The required condition to perform this step is to login to your economic account.
                                Click on link Generate new token, the system automatically connects to the economic system.
                            </p>
                            <div className="text-center">
                                <a href="#"><img src={webhook} height={400} /></a>
                            </div>
                            <p>And then paste to WEB HOOKS in Economic.</p>
                            <div className="text-center">
                                <a href="#"><img src={webhookEconomic} height={400} /></a>
                            </div>
                            <p>Create new web hook by pasting the information on the form of Step 2 into the web hook form.</p>
                            <div className="text-center">
                                <a href="#"><img src={webhookEconomicForm} height={400} /></a>
                            </div>
                            <p>
                                After paste the complete information and then press the Save button.
                                <br />
                                You have successfully created Connect between shipping app and economic, and continue Step 3
                            </p>
                        </li>
                        <li>
                            <h5>Step 3:Input account shipment company</h5>
                            <div className="text-center">
                                <a href="#"><img src={shippingInformation} height={400} /></a>
                            </div>
                            <p className="text-center">
                                Successful Settings message
                            </p>
                            <div className="text-center">
                                <a href="#"><img src={successFirstLogin} height={400} /></a>
                            </div>
                        </li>
                    </ul>
                </section>
                <section id="forgot-password">
                    <h5>3. Forgot your password</h5>
                    <div className="text-center">
                        <a href="#"><img src={forgotPassword} height={400} /></a>
                    </div>
                    <p className="text-center">Enter the email address you want to change password.</p>
                    <div className="text-center">
                        <a href="#"><img src={forgotPasswordClient} height={400} /></a>
                    </div>
                    <p className="text-center">If you do not want to change your password, you can back to the Login page.</p>
                    <p className="text-center">Email confirmation of reset password.</p>
                    <div className="text-center">
                        <a href="#"><img src={verifyForgotPassword} height={200} /></a>
                    </div>
                    <p className="text-center">Input new password.</p>
                    <div className="text-center">
                        <a href="#"><img src={resetPassword} height={400} /></a>
                    </div>
                    <p className="text-center">After inputting the new password and you Submit, you can re-login the user with the new password</p>
                </section>
                <section id="shipping-management">
                    <h5>4. Shipping Management/Orders</h5>
                    <ul>
                        <li>
                            <p>Orders list : Orders are synced from Invoice Economic</p>
                            <div className="text-center">
                                <a href="#"><img src={orders} height={400} /></a>
                            </div>
                        </li>
                        <li>
                            <p>Open tab : Orders synced from economic will default to Open status.</p>
                            <div className="text-center">
                                <a href="#"><img src={openTab} height={400} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>On Hold tab : Order is in Open status, not enough information or do not want to ship, can move to On hold status</p>
                            <p>Select the order to change the status and click on button On Hold</p>
                            <div className="text-center">
                                <a href="#"><img src={onHoldTab} height={400} width={1000} /></a>
                            </div>
                            <p>And then click Submit.</p>
                            <div className="text-center">
                                <a href="#"><img src={onHoldSubmit} height={400} width={1000} /></a>
                            </div>
                            <p>Successfully moved order to On Hold</p>
                            <div className="text-center">
                                <a href="#"><img src={onHoldTab2} height={400} width={1000} /></a>
                            </div>
                            <p>You can also change the status of an order from On Hold to Open.
                                The system will still update the order information from Economic when the order is in the On Hold status
                            </p>
                            <div className="text-center">
                                <a href="#"><img src={changeOpenStatus} height={400} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>Shipped tab :</p>
                            <p>You can ship orders by selecting multiple orders and clicking the Ship button
                                Or ship each order by clicking on the ship icon in the list of open orders
                            </p>
                            <div className="text-center">
                                <a href="#"><img src={shipButton} height={400} width={1000} /></a>
                            </div>
                            <p>You need to select Company shipment and Service shipment.</p>
                            <div className="text-center">
                                <a href="#"><img src={shipMethod} height={400} width={1000} /></a>
                            </div>
                            <p>After Submit, the system will display the ship code and label information sent from the Company shipment.</p>
                            <div className="text-center">
                                <a href="#"><img src={orderAfterShipped} height={400} width={1000} /></a>
                            </div>
                            <p>At the same time, the information of Company shipment is also sent to Economic</p>
                            <div className="text-center">
                                <a href="#"><img src={economicUpdate} height={400} width={1000} /></a>
                            </div>
                            <p>Note: Company shipment information is only sent to economic when Invoice has a Delivery Date</p>
                        </li>
                        <li>
                            <p>Unavailable tab :</p>
                            <p>The system will automatically move the order from Open status to Unavailble when the Invoice on economic is deleted. And will also automatically update the order on the shipping app if the Invoice created in Economic has the same code as the order.</p>
                            <div className="text-center">
                                <a href="#"><img src={unvailableTap} height={400} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>Logs:</p>
                            <p>The system will logs all updates and changes in the order when the economic update is available</p>
                            <div className="text-center">
                                <a href="#"><img src={logs} height={400} width={1000} /></a>
                            </div>
                        </li>

                    </ul>
                </section>
                <section id="setting">
                    <h5>5. Setting</h5>
                    <ul>
                        <li>
                            <p>Business Info:</p>
                            <p>User information will be updated in Business details</p>
                            <div className="text-center">
                                <a href="#"><img src={BusinessInfo} height={400} width={1000} /></a>
                            </div>
                            <p>Change Password: Change password while logged in</p>
                            <div className="text-center">
                                <a href="#"><img src={changePassword} height={400} width={1000} /></a>
                            </div>
                            <div className="text-center">
                                <a href="#"><img src={changePassword2} height={400} width={1000} /></a>
                            </div>
                        </li>
                        <li>
                            <p>E-conomic:</p>
                            <p>You can update Economic information again  after inputting information from steps 1, 2 when logging in</p>
                            <p>Token: Generate new token</p>
                            <div className="text-center">
                                <a href="#"><img src={economic} height={400} width={1000} /></a>
                            </div>
                            <p>Token generated from Economics using app.</p>
                            <div className="text-center">
                                <a href="#"><img src={addApp} height={400} width={1000} /></a>
                            </div>
                            <p>Complete the token collection when clicking Submit.</p>
                            <div className="text-center">
                                <a href="#"><img src={generateToken} height={400} width={1000} /></a>
                            </div>
                            <p>Webhook: You must register web hooks e-conomic. please following copy/paste text below.</p>
                            <div className="text-center">
                                <a href="#"><img src={economicCopyAndPaste} height={400} width={1000} /></a>
                            </div>
                            <p>WEBHOOKS in Economic</p>
                            <div className="text-center">
                                <a href="#"><img src={webhookEconomicSetting} height={400} width={1000} /></a>
                            </div>
                            <p>Create new web hook by pasting the information on the form Webhook into the web hook Economic</p>
                            <div className="text-center">
                                <a href="#"><img src={webhookShortcuts} height={400} width={1000} /></a>
                            </div>
                            <p>After paste the complete information and then press the Save button.</p>
                            <p>Note: Only tokens whose App name is Shipping with 2app can connect to the shipping app system</p>
                            <div className="text-center">
                                <a href="#"><img src={granteApp} height={300} width={1000} /></a>
                            </div>
                        </li>
                    </ul>
                </section>
                <section id="shipped-information">
                    <h5>6. Shipped Information</h5>
                    <p>Information of orders has been shipped and you can export to an excel file</p>
                    <div className="text-center">
                        <a href="#"><img src={shipment} height={400} width={1000} /></a>
                    </div>
                </section>

            </div>
        </div>
    )
}

// @ts-ignore
export default React.memo(Document);
