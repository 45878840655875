import React, {useContext} from "react";
import Header from "../components/Header";
import LeftSideBar from "./LeftSideBar";
import {RouteDefine} from "../../routes/RouteDefine";
import LeftSideBar01 from "./LeftSideBar01";
import {useHistory} from "react-router-dom";
import {LayoutContext, LayoutDefine} from "../../context/LayoutContext";
import Navbar from "../components/Navbar";


export function DashboardTemplate({children}: HTMLElement | any) {
    const {layout} : any = useContext(LayoutContext);
    if(layout == LayoutDefine.main)
        return (<MainLayout>{children}</MainLayout>)
    else if(layout == LayoutDefine.second)
        return (<SecondLayout>{children}</SecondLayout>)
    else if(layout == LayoutDefine.second01)
        return (<Second01Layout>{children}</Second01Layout>)
    else
        return (<ExtLayout>{children}</ExtLayout>)

}

function MainLayout({children}: HTMLElement | any) {

    return (
        <div id="layout-wrapper">
            <Header/>
            <Navbar/>
            <div className="vertical-overlay"/>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <LeftSideBar/>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SecondLayout({children}: HTMLElement | any) {
    const history = useHistory();
    return (
        <div id="layout-wrapper">
            {/* =================================Header============================= */}
            <div className="header-detail p02">
                <div className="row h-100 align-items-center">
                    <div className="col-md-7">
                        <div className="title-detail">
                            <a onClick={() => {
                                history.push(RouteDefine.order)
                            }} className="cursor-pointer pe-3 border-right-ams fs-20"><i
                                className="las la-times cl-font-zp fw-bold"/></a><span className="ml-3">Cancel</span>
                        </div>
                    </div>
                    <div className="col-md-5 d-flex justify-content-end">
                    </div>
                </div>
            </div>

            <div className="vertical-overlay"/>
            {/* =================================End Header============================= */}
            <div className="main-content">
                <div className="page-content mt-0 pt-3">
                    <div className="container-fluid">
                        <div className="row">
                            <LeftSideBar01/>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Second01Layout({children}: HTMLElement | any) {
    const history = useHistory();
    return (
        <div id="layout-wrapper">
            {/* =================================Header============================= */}
            <div className="header-detail p02">
                <div className="row h-100 align-items-center">
                    <div className="col-md-7">
                        <div className="title-detail">
                            <a onClick={() => {
                                history.push(RouteDefine.order)
                            }} className="cursor-pointer pe-3 border-right-ams fs-20"><i
                                className="las la-times cl-font-zp fw-bold"/></a><span className="ml-3">Cancel</span>
                        </div>
                    </div>
                    <div className="col-md-5 d-flex justify-content-end">
                    </div>
                </div>
            </div>

            <div className="vertical-overlay"/>
            {/* =================================End Header============================= */}
            <div className="container-detail mt-4">
                {children}
            </div>

        </div>
    )
}
function ExtLayout({children}: HTMLElement | any) {

    return (
        <div id="layout-wrapper">
            {/* =================================Header============================= */}
            <Header/>
            <Navbar/>
            <div className="vertical-overlay"/>
            {/* =================================End Header============================= */}
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
