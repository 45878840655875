import React from "react";
import ImgPageNotFound from "../../../assets/images/ams/404.png";
import {useHistory} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";

const PageNotFound = () => {
    const history = useHistory()

    return (
        <React.Fragment>
            <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="auth-page-content overflow-hidden">
                    <div className="container-detail">
                        <div className="overflow-hidden p-5 bdr-10  m-auto">
                            <div className="row g-0">
                                <div className="col-lg-12">
                                    <div>
                                        <div className="d-flex justify-content-center">
                                            <div><a href="#"><img src={ ImgPageNotFound } /></a></div>
                                        </div>
                                        <div>
                                            <div className="mt-5 position-relative text-center">
                                                <h2 className="mb-4 mt-3 fs-23 fw-bold">SORRY, PAGE NOT FOUND 😭</h2>
                                                <p className="cl-bd">The page you are looking for not available!</p>
                                                <div className="mt-5">
                                                    <a href=""
                                                       className="btn undefined btn bg-orange-zp bdr-10"
                                                       type="button"
                                                       onClick={() => { history.push(RouteDefine.order) }}
                                                    >
                                                        <i className="mdi mdi-home"></i>
                                                        Back to home
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default PageNotFound;
