import React, { useCallback, useState, useEffect } from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as yup from "yup";
import { getValidation, SetupConfig } from "./config";
import QButton from "../../../common/components/QButton";
import AuthApi from "../../../common/api/functions/Auth";
import { config } from "../../../config";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/rootReducer";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import SetupApi from "../../../common/api/functions/Setup";

interface Props {

}

const initialValues: any = {
    gls_username: "",
    gls_password: "",
    gls_customer_id: "",
    gls_contact_id: "",
    economic_token: "",
}

const Setup: React.FC<Props> = ({ }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const { location } = history;

    const formik = useFormik({
        initialValues: { ...initialValues },
        enableReinitialize: true,
        validationSchema: getValidation(step, yup),
        onSubmit: async (values) => {
            if (step < 3) {
                setStep(step + 1)
            } else {
                let model = {
                    economic_info: {
                        economic_token: values.economic_token,
                    },
                    company_ship_info: {
                        gls_username: values.gls_username,
                        gls_password: values.gls_password,
                        gls_customer_id: values.gls_customer_id,
                        gls_contact_id: values.gls_contact_id,
                    }
                }

                let response: ResponseInterface | any = await AuthApi.setupFirstLogin(model)

                if (response?.status === config.STATUS_CODE.OK) {
                    dispatch(setToast({
                        message: response?.data?.message,
                        type: config.TOAST_TYPE.SUCCESS,
                    }))

                    // history.push(RouteDefine.order)
                    setStep(4);
                } else {
                    // dispatch(setToast({
                    //     message: response?.data?.message,
                    //     type: config.TOAST_TYPE.ERROR,
                    // }))
                    setStep(5)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="mt-5 position-relative">
                <form method="">
                    {SetupConfig(step, formik)}
                    <div className="mt-5">
                        {
                            step < 4 ? (
                                <QButton
                                    className={'btn bdr-10 fs-16'}
                                    width={100}
                                    type={'primary'}
                                    content={step < 3 ? "Next" : "Submit"}
                                    size={''}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                />
                            ) : null
                        }
                        {
                            step > 1 && step <= 3 ? (
                                <QButton
                                    className={'btn bdr-10 fs-16 mt-3'}
                                    width={100}
                                    content={"Back"}
                                    size={''}
                                    onClick={() => {
                                        setStep(step - 1)
                                    }}
                                />
                            ) : null
                        }
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default Setup;
