import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import { UseSecondLayout } from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import SetupApi from "../../../common/api/functions/Setup";
import BodyEconomicComponent from "./components/BodyEconomicComponent";
import { SetupInterface } from "../../../interfaces/private/SetupInterface";
import { config } from "../../../config";
import { QHelpers } from "../../../common/helpers";
// @ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styles from "../../public/Setup/index.module.css";
import QIconCheck from "../../../common/components/QIcon/QIconCheck";


export function Economic() {

    const [setup, setSetup] = useState<SetupInterface>({});
    const [loading, setLoading] = useState(true);
    const [createNewClick, setCreateNewClick] = useState(false);
    const [copied1, setCopied1] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [copied3, setCopied3] = useState(false);

    useEffect(() => {
        if(copied1){
            setTimeout(() => {
                setCopied1(false)
            }, 1000)
        }
    }, [copied1])

    useEffect(() => {
        if(copied2){
            setTimeout(() => {
                setCopied2(false)
            }, 1000)
        }
    }, [copied2])

    useEffect(() => {
        if(copied3){
            setTimeout(() => {
                setCopied3(false)
            }, 1000)
        }
    }, [copied3])

    UseSecondLayout();
    useEffect(() => {
        if (loading) {
            load().then(async () => {
                await setLoading(false);

                let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search)

                if (objectParams?.token) {
                    await setSetup({
                        ...setup,
                        economic_token: objectParams?.token,
                    })
                    await setCreateNewClick(true);
                }
            });
        }
    }, [loading])

    const load = async () => {
        try {
            await SetupApi.detail().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setSetup(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={setCreateNewClick}
                headerContent={'Economic'}
                bodyContent={(
                    <BodyEconomicComponent
                        model={setup}
                        setOpen={setCreateNewClick}
                        setSetup={setSetup}
                        setLoadingFunc={setLoading}
                    />
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading} />
                <div className="row ">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">Economic</div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">Token</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                            data-bs-target="#showModalBusinessDetail"><i
                                                className="las la-pen fs-24 cl-br-ams ml-2"
                                                onClick={() => {
                                                    setCreateNewClick(true);
                                                }}
                                            /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="mb-1">{setup.economic_token}</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-2"><a href={QHelpers.string.generateUrlEconomic()}>Generate new token</a></div>
                                </div>

                            </div>
                            <br />
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">Web hook</h2>
                                        <span id="helpBlock" className="help-block">You must register web hooks e-conomic. please following copy/paste text below.</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <table className={"table table-striped"}>
                                            <tbody>
                                                <tr>
                                                    <td>1.</td>
                                                    <td>Name</td>
                                                    <td>
                                                        <div className="row d-flex">
                                                            <div className="col-md-8">
                                                                <p>{setup?.webhook?.name}</p>
                                                            </div>
                                                            <div className="col-md-4 text-end">
                                                                <CopyToClipboard text={setup?.webhook?.name}>
                                                                    <a className={'cursor-pointer'}>
                                                                        <i
                                                                            className="las la-copy fs-24 cl-br-ams ml-2"
                                                                            onClick={() => setCopied1(true)}
                                                                        />
                                                                    </a>
                                                                </CopyToClipboard>
                                                                {
                                                                    copied1 && (
                                                                        <div className={`${styles.popupCopied} shadow-lg`}>
                                                                             <span className="text-success">
                                                                                Copied <QIconCheck className={'text-success'} />
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2.</td>
                                                    <td>Type</td>
                                                    <td>{setup?.webhook?.type}</td>
                                                </tr>
                                                <tr>
                                                    <td>3.</td>
                                                    <td>Url</td>
                                                    <td> <div className="row d-flex">
                                                        <div className="col-md-8">
                                                            <p>{setup?.webhook?.url}</p>
                                                        </div>
                                                        <div className="col-md-4 text-end">
                                                            <CopyToClipboard text={setup?.webhook?.url}>
                                                            <a className={'cursor-pointer'}>
                                                                <i className="las la-copy fs-24 cl-br-ams ml-2" onClick={() => setCopied2(true)}/>
                                                            </a>
                                                            </CopyToClipboard>
                                                            {
                                                                copied2 && (
                                                                    <div className={`${styles.popupCopied} shadow-lg`}>
                                                                        <span className="text-success">
                                                                            Copied <QIconCheck className={'text-success'} />
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div></td>
                                                </tr>
                                                <tr>
                                                    <td>4.</td>
                                                    <td>Data posted</td>
                                                    <td>
                                                        <div className="row d-flex">
                                                            <div className="col-md-8">
                                                                <p>{setup?.webhook?.data_posted}</p>
                                                            </div>
                                                            <div className="col-md-4 text-end cursor-pointer">
                                                                <CopyToClipboard text={setup?.webhook?.data_posted}>
                                                                    <a onClick={() => { setCopied3(true) }}>
                                                                        <i className="las la-copy fs-24 cl-br-ams ml-2"/>
                                                                    </a>
                                                                </CopyToClipboard>

                                                                {
                                                                    copied3 && (
                                                                        <div className={`${styles.popupCopied} shadow-lg`}>
                                                                            <span className="text-success">
                                                                                Copied <QIconCheck className={'text-success'} />
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

// @ts-ignore
export default React.memo(Economic);
