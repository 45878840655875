import {TableHeaderInterface} from "../../../../interfaces/TableHeaderInterface";

const headers : Array<TableHeaderInterface> = [
    {
        label: "",
        checkbox: true,
    },
    {
        label: "Order no",
        sort: 'orderNumber',
        className: "w-10p"
    },
    {
        label: "Customer",
        className: "w-15p"
    },
    {
        label: "Order date",
        sort: 'date',
        className: "w-10p"
    },
    {
        label: "Delivery date",
        sort: 'delivery_deliveryDate',
        className: "w-10p"
    },
    {
        label: "Delivery address",
        className: "w-25p"
    },
    {
        label: "Weight",
        className: "text-right w-10p"
    },
    {
        label: "Status",
        className: "text-center"
    },
    {
        label: "VIA",
    },
    {
        label: "Service",
    },
    {
        label: "",
    },
]

export const OrderConfig = {
    headers,
}
