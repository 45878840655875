import React, { useState } from "react";
import QTextBox from "../../../common/components/QTextBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import QLoading from "../../../common/components/QLoading";
import QButton from "../../../common/components/QButton";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import { useDispatch } from "react-redux";
import RegisterApi from "../../../common/api/functions/Register";
import { setToast } from "../../../app/rootReducer";
import QModal from "../../../common/components/QModal";
import { QHelpers } from "../../../common/helpers";

export function Register() {
    const history = useHistory();
    const dispatch = useDispatch();
    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const { location } = history;
    let { state }: any = location;
    let model = {
        token : objectParams?.token
    }
    const formik = useFormik({
        initialValues: {
            email: state === undefined ? '' : state.email,
            password: '',
            password_confirm: '',
            name: state === undefined ? '' : state.name,
            company_name: '',
            address: state === undefined ? '' : state.addressLine1,
            zip: state === undefined ? '' : state.zip,
            city: state === undefined ? '' : state.city,
            country: '',
            phone: state === undefined ? '' : state.phoneNumber,
            website: state === undefined ? '' : state.website,
            cvr_no: state === undefined ? '' : state.companyIdentificationNumber,
            token : model.token
        },
        validationSchema: Yup.object({
            email: Yup.string().required().email(),
            // password: Yup.string().required("Password can not be blank").matches(
            //     /(.{6,})/,
            //     "Must be at least 6 characters"
            // ),
            password: Yup.string().required().min(6),
            password_confirm: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Repeat password does not match'),
            name: Yup.string().required(),
            // company_name: Yup.string().required(),
            // phone: Yup.string().required(),
            // cvr_no: Yup.string().required(),
        }),
        onSubmit: async (values, { setFieldError }) => {
            await setLoading(true);
            await RegisterApi.createAccount(values).then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setMessage(response.data?.message);
                    await dispatch(setToast({
                        message: 'Create new account success.',
                        type: config.TOAST_TYPE.SUCCESS,
                    }))
                    await setOpen(true);
                }
            }, (response) => {
                if (typeof response.data.message === "string") {
                    // setMessageErr(response?.data?.message);
                    dispatch(setToast({
                        message: response.data?.message,
                        type: config.TOAST_TYPE.ERROR,
                    }))
                } else {
                    let message = response.data.message;
                    for (let key in message) {
                        setFieldError(key, message[key][0]);
                    }
                }
            }).catch(() => {
                dispatch(setToast({
                    message: "Create new account fail.",
                    type: config.TOAST_TYPE.ERROR,
                }))
            });


            await setLoading(false);
        }
    })
    return (
        <div>
            <div className="mt-4 position-relative">
                <QLoading loading={loading} />
                <QModal
                    open={open}
                    setOpen={() => {
                        setOpen(false)
                    }}
                    loading={loading}
                    headerContent={'Create New Account'}
                    bodyContent={(
                        <React.Fragment>
                            {message}
                        </React.Fragment>
                    )}
                />
                <form action="" method="">
                    <h2 className="mb-4 mt-3  fs-32 fw-400 cl-orange-zp"> Create your account</h2>

                    <QTextBox
                        label={'Email'}
                        placeholder={''}
                        // className={'Enter username'}
                        value={formik.values.email}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('email', value)
                        }}
                        error={formik.touched?.email && formik.errors?.email}
                    />

                    <QTextBox
                        type={'password'}
                        label={'Password'}
                        placeholder={''}
                        value={formik.values.password}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('password', value)
                        }}
                        error={formik.touched?.password && formik.errors?.password}
                    />
                    <QTextBox
                        type={'password'}
                        label={'Repeat Password'}
                        placeholder={''}
                        value={formik.values.password_confirm}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('password_confirm', value)
                        }}
                        error={formik.touched?.password_confirm && formik.errors?.password_confirm}
                    />
                    <QTextBox
                        label={'Name'}
                        placeholder={''}
                        value={formik.values.name}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('name', value)
                        }}
                        error={formik.touched?.name && formik.errors?.name}
                    />
                    {/*<QTextBox
                        label={'Company Name'}
                        placeholder={''}
                        value={formik.values.company_name}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('company_name', value)
                        }}
                        error={formik.touched?.company_name && formik.errors?.company_name}
                    />

                    <QTextBox
                        label={'Phone'}
                        placeholder={''}
                        value={formik.values.phone}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('phone', value)
                        }}
                        error={formik.touched?.phone && formik.errors?.phone}
                    />


                    <QTextBox
                        label={'Address'}
                        placeholder={''}
                        value={formik.values.address}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('address', value)
                        }}
                        error={formik.touched?.address && formik.errors?.address}
                    />
                    <QTextBox
                        label={'Zip'}
                        placeholder={''}
                        value={formik.values.zip}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('zip', value)
                        }}
                        error={formik.touched?.zip && formik.errors?.zip}
                    />
                    <QTextBox
                        label={'City'}
                        placeholder={''}
                        value={formik.values.city}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('city', value)
                        }}
                        error={formik.touched?.city && formik.errors?.city}
                    />
                    <QTextBox
                        label={'Country'}
                        placeholder={''}
                        value={formik.values.country}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('country', value)
                        }}
                        error={formik.touched?.country && formik.errors?.country}
                    />
                    <QTextBox
                        label={'Website'}
                        placeholder={''}
                        value={formik.values.website}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('website', value)
                        }}
                        error={formik.touched?.website && formik.errors?.website}
                    />
                    <QTextBox
                        label={'CVR No.'}
                        placeholder={''}
                        value={formik.values.cvr_no}
                        className={'form-control-zp bg-transparent bd-bottom'}
                        onChange={(value: string) => {
                            formik.setFieldValue('cvr_no', value)
                        }}
                        error={formik.touched?.cvr_no && formik.errors?.cvr_no}
                    />*/}


                    <div className="mt-4">
                        <QButton
                            content={'Create Account'}
                            type={'success'}
                            width={'w-100'}
                            size={''}
                            className={'btn bg-orange-zp w-100 bdr-10 fs-16'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        />
                    </div>
                    <div className="mt-4">
                        <br />
                        <br />
                        <span>Already have a 2app shipping ID? </span><a className="cl-orange-zp fw-bold" href={'#'} onClick={() => { history.push(RouteDefine.login) }}>Log in</a>
                    </div>

                </form>
            </div>
        </div>
    )
}
