import { ppid } from "process";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import OrderApi from "../../../../common/api/functions/Order";
import QModal from "../../../../common/components/QModal";
import { LogOrderInterface } from "../../../../interfaces/private/OrderInterface";

interface Props {
    open: any,
    orderId: number | any,
    setOpen: Function
}

const PopupDetailLog: React.FC<Props> = ({ open, orderId, setOpen }) => {
    const [log, setLog]: any = useState({});
    let id: any = open;
    let arrayLog: any;
    let lineArray: any;
    let lineArrayDelete: any;
    let deleteIndex: any;
    let arrayLogAfterDelete: any;
    useEffect(() => {
        OrderApi.getLogById(orderId, id).then(async (response: any) => {
            await setLog(
                response?.data?.data.data ?? []
            )
        })
    }, [id])
    arrayLog = Object.keys(log).map((key: any) => [(key), log[key]]);

    if (arrayLog.length <= 2) {
        for (let i in arrayLog) {
            if (arrayLog[i][0] === "response") {
                let message = 'Order ' + orderId + ' shipped with T&T: ' + arrayLog[i][1].ConsignmentId;
                arrayLog[0][0] = 'Order';
                arrayLog[0][1] = message;
                arrayLog.pop();
            }
        }
    } else {
        for (let i in arrayLog) {
            if (arrayLog[i][0] === 'lines') {
                lineArray = Object.keys(arrayLog[i][1][0]).map((v: any) => [(v), arrayLog[i][1][0][v]]);
                deleteIndex = i;
            }
        }

        arrayLogAfterDelete = arrayLog.filter((value: any, index: number) => index != deleteIndex)
        if (lineArray !== undefined) {
            lineArrayDelete = lineArray.filter((i: any) => {
                if (i[0] === 'product' || i[0] === 'unit' || i[0] === "departmentalDistribution") {
                    return false
                }
                return true;
            })
            arrayLog = [...arrayLogAfterDelete, ...lineArrayDelete]
        }
    }
    // console.log(arrayLog);

    return (
        <React.Fragment>
            <QModal
                open={open !== ''}
                setOpen={() => { setOpen('') }}
                headerContent={'Customer Detail'}
                className={'modal-md-zp'}
                bodyContent={(
                    <React.Fragment>
                        <Container>
                            <Row>
                                <div className="" style={{ maxHeight: '70vh', overflowY: 'auto' }} >
                                        Order: {Object.keys(arrayLog).map((i: any) => <p>{arrayLog[i][0]}: {arrayLog[i][1]}</p>)}
                                </div>
                            </Row>
                        </Container>
                    </React.Fragment>
                )}
            />

        </React.Fragment>
    )
}

export default PopupDetailLog;
