import React from "react";
import {FormGroup, Label, FormText, Input, FormFeedback} from 'reactstrap'
import Select from 'react-select'
import {SelectOptionInterface} from "../../../interfaces/SelectOptionInterface";

interface Props {
    value: string|any,
    options: Array<SelectOptionInterface>,
    onChange: Function|any,
    error?: string|any,
    placeholder?: string|any,
    isMulti?: boolean,
    label?: string|any,
    isDisabled?: boolean|any,
}

const QSelect: React.FC<Props> = ({
    label,
    value,
    options = [],
    onChange,
    placeholder = '',
    error = '',
    isMulti= false,
    isDisabled = false,
}) => {

    return (
        <div className="mb-3">
            { label ? (
                <Label>
                    { label }
                </Label>
            ) : null }
            <Select
                className={'QSelectCustom'}
                isDisabled={isDisabled}
                isMulti={isMulti}
                placeholder={placeholder}
                onChange={(event: Event|any) => {
                    if(onChange) onChange(event);
                }}
                options={options}
                value={value}
            />
            { error ? (
                <FormFeedback>
                    { error }
                </FormFeedback>
            ) : null }

        </div>
    )
}

export default React.memo(QSelect);