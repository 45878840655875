import React, { useEffect, useState } from "react";
import QSelect from "../../../../common/components/QSelect";
import ShipmentApi from "../../../../common/api/functions/Shipment";
import { ResponseInterface } from "../../../../interfaces/ResponseInterface";
import { ServiceInterface, ServiceShipmentInterface } from "../../../../interfaces/private/ServiceShipmentInterface";
import { config } from "../../../../config";
import { Formik, useFormik } from "formik";
import { SelectOptionInterface } from "../../../../interfaces/SelectOptionInterface";
import { QHelpers } from "../../../../common/helpers";
import { Button, Col, Row } from "reactstrap";
import OrderApi from "../../../../common/api/functions/Order";
import { OrderInterface } from "../../../../interfaces/private/OrderInterface";
import { useDispatch } from "react-redux";
import { setToast } from "../../../../app/rootReducer";
import QButton from "../../../../common/components/QButton";
import QModal from "../../../../common/components/QModal";
import QAlert from "../../../../common/components/Genneral/QAlert";
import QTextBox from "../../../../common/components/QTextBox";
import { ShipmentService } from "../../../../common/constant/OrderConstant";
import { useTranslation } from 'react-i18next';
import { ShipmentConstant } from "../../../../common/constant/ShipmentConstant";

interface Props {
    open: number | boolean, // open > 0 show
    order: OrderInterface | any,
    setOpen: Function,
    setLoading: Function,
    isMulti: boolean,
}

const BodyDeliveryComponent: React.FC<Props> = ({ setOpen, order, setLoading, open, isMulti, ...props }) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const [company, setCompany] = useState<Array<ServiceShipmentInterface>>([]);
    const [cLoading, setCLoading] = useState(true);
    const [messageErr, setMessageErr] = useState<any>("");

    const formik = useFormik({
        initialValues: {
            service: '',
            company: '',
            deposit_message: '',
        },
        onSubmit: async (values: any) => {
            try {
                let _company: any = company.find((c: ServiceShipmentInterface) => c.id == values.company);
                let _service = _company.service_shipments.find((s: ServiceInterface) => s.id == values.service)

                let model: any = {
                    shipment_service_id: values.service,
                    shipment_id: values.company,
                    deposit_message: values.deposit_message
                }
                console.log('order', order)
                if (_service.type == 5) model.countryNumber = 1;
                if (_service.type == 6) model.deposit_message = 2;
                await setCLoading(true);
                if (order?.id) {
                    await OrderApi.postShipment(order.id, model).then(async (response: ResponseInterface | any) => {
                        if (response?.status === config.STATUS_CODE.OK) {
                            await setOpen(0);
                            await dispatch(setToast({
                                message: t("order.shippingSuccess"),
                                type: config.TOAST_TYPE.SUCCESS,
                            }))
                            await setLoading(true);
                        } else {
                            setMessageErr(response?.data?.message);
                        }
                    }, (response) => {
                        // console.log(response?.data?.message);
                        setMessageErr(response?.data?.message);
                    })
                } else {
                    await OrderApi.postShipmentAll({
                        ...model,
                        ids: open
                    }).then(async (response: ResponseInterface | any) => {
                        if (response?.status === config.STATUS_CODE.OK && response?.data?.error === false) {
                            await setOpen(0);
                            await dispatch(setToast({
                                message: t("order.shippingSuccess"),
                                type: config.TOAST_TYPE.SUCCESS,
                            }))
                            await setLoading(true);
                        } else {
                            let content: any = response?.data?.data?.map((item: any) => {
                                return <p>{item?.data.message}</p>
                            })

                            setMessageErr(content);
                        }
                    }, (response) => {
                        // console.log(response?.data?.message);
                        setMessageErr(response?.data?.message);
                    })
                }
                await setCLoading(false);
            } catch (err: any) {
                await setMessageErr(err);
                await setCLoading(false);
            }
        }
    })


    useEffect(() => {

        if (!order.shipment_id) {
            ShipmentApi.getAll().then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    let companies = Array.isArray(response?.data?.data?.data) ? response?.data?.data?.data : [];
                    await setCompany(companies)

                    if (companies.length) {
                        let company = companies.find((c: ServiceShipmentInterface) => c.id === ShipmentConstant.company.GLS);
                        if (company) {
                            await formik.setFieldValue('company', company.id)
                            let service = company.service_shipments.find((s: ServiceInterface) => s.type === ShipmentConstant.service.PRIVATE_DELIVERY_SERVICE);
                            if (service) {
                                await formik.setFieldValue('service', service.type)
                            }
                        }


                    }

                    await setCLoading(false)
                }
            })
        }

        return () => {
            setCLoading(true);
            setMessageErr("");
        }
    }, [])

    const handleGetService = (companyId: number | any) => {
        let _company = company.find((c: ServiceShipmentInterface) => c.id == companyId);

        if (!_company) return [];

        return _company.service_shipments?.map((c: ServiceInterface) => ({ label: c.name, value: c.type }))

    }

    return (
        <React.Fragment>

            <QModal
                open={open != 0}
                setOpen={() => {
                    setOpen(0)
                    setLoading(true);
                }}
                loading={cLoading}
                headerContent={'Shipment method'}
                bodyContent={(
                    <React.Fragment>
                        <Row>

                            <Col>
                                {
                                    messageErr ? <div className="alert alert-warning"><p dangerouslySetInnerHTML={{__html:messageErr}}></p></div> : null
                                }
                                <QSelect
                                    isDisabled={!!order.shipment_id}
                                    label={'Company Shipment'}
                                    value={
                                        order.shipment_id ? { label: order.company_shipment.name, value: '' } : (
                                            QHelpers.object.mapSelectOption(
                                                formik.values.company,
                                                company?.map((c: ServiceShipmentInterface) => ({ label: c.name, value: c.id }))
                                            )
                                        )
                                    }
                                    options={
                                        company?.map((c: ServiceShipmentInterface) => ({ label: c.name, value: c.id }))
                                    }
                                    onChange={(option: SelectOptionInterface) => {
                                        formik.setFieldValue('company', option.value)
                                        formik.setFieldValue('service', '')
                                    }}
                                />

                                <QSelect
                                    isDisabled={!!order.shipment_id}
                                    label={'Service Shipment'}
                                    value={
                                        order.shipment_id ? { label: order.service_shipment.name, value: '' } : (
                                            QHelpers.object.mapSelectOption(
                                                formik.values.service,
                                                handleGetService(formik.values.company)
                                            )
                                        )
                                    }
                                    options={handleGetService(formik.values.company)}
                                    onChange={(option: SelectOptionInterface) => {
                                        formik.setFieldValue('service', option.value)
                                    }}
                                />

                                <QTextBox
                                    isVisible={formik.values.service == ShipmentService.deposit_service}
                                    label={'Deposit Message'}
                                    value={formik.values.deposit_message}
                                    onChange={(value: string) => {
                                        formik.setFieldValue('deposit_message', value)
                                    }}
                                />
                            </Col>
                        </Row>

                        {order.shipment_id ? (
                            <Row>
                                <Col>
                                    Track and trace: {order.traceandtrace}
                                </Col>
                            </Row>
                        ) : null}
                        <Row>
                            <Col>
                                <div className={'d-flex justify-content-end gap-2'}>
                                    <QButton
                                        type={'default'}
                                        onClick={() => {setMessageErr(""); setOpen(0); setLoading(true);}}
                                        content={t('common.button.cancel')}
                                    />

                                    {
                                        order.shipment_id > 0 ? null : (
                                            <QButton
                                                type={'primary'}
                                                onClick={() => { formik.handleSubmit() }}
                                                content={t('common.button.submit')}
                                            />
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            />


        </React.Fragment>
    )
}

export default BodyDeliveryComponent
